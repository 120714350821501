import React from 'react'
import { Helmet } from 'react-helmet'

import Nav from '../nav'

import './index.css'
import 'typeface-montserrat'
import 'typeface-lora'

export default ({ children }) => (
  <main style={{ display: 'grid' }}>
    <Helmet>
      <html lang="en" />
      <meta name="description" content="laitine.io – A Designer's Portfolio" />
      <meta
        name="keywords"
        content="portfolio, design, development, agile, lean ux, design thinking, lean startup, tech, speaker, writer, ux strategy, design management'"
      />
      <title>laitine.io – Designer on the Web</title>
    </Helmet>
    <Nav />
    {children}
    <div className="footnote">
      <span>© 2018-{new Date().getFullYear()} Niko Laitinen</span>
    </div>
  </main>
)
