import React from 'react'
import { Link } from 'gatsby'

import * as navStyles from './nav.module.css'
import logoImage from '../../images/logo-moderne-52px.svg'

const Nav = () => (
  <nav className={navStyles.container}>
    <div className={navStyles.logo}>
      <Link to="/">
        <img src={logoImage} title="Home" alt="Home" />
      </Link>
    </div>
    <div className={navStyles.block}>
      <h1 className={navStyles.item}>
        <Link
          to="/writing"
          className={navStyles.link}
          activeClassName={navStyles.active}
        >
          Writing
        </Link>
      </h1>
      <h1 className={navStyles.item}>
        <Link
          to="/speaking"
          className={navStyles.link}
          activeClassName={navStyles.active}
        >
          Speaking
        </Link>
      </h1>
      <h1 className={navStyles.item}>
        <Link
          to="/timeline"
          className={navStyles.link}
          activeClassName={navStyles.active}
        >
          Timeline
        </Link>
      </h1>
      <h1 className={navStyles.item}>
        <Link
          to="/story"
          className={navStyles.link}
          activeClassName={navStyles.active}
        >
          Story
        </Link>
      </h1>
    </div>
  </nav>
)

export default Nav
